import React, { ReactNode } from "react"

import Footer from "@modules/auth/components/Footer"

interface AuthLayoutProps {
  children: ReactNode
  leftArea?: ReactNode
  className?: string
  showFooter?: boolean
}

const AuthLayout = ({ children, leftArea, className, showFooter = true }: AuthLayoutProps) => (
  <div className={`relative flex h-screen	 w-full flex-col md:flex-row ${className ?? ""}`}>
    <div
      className={
        "flex-1 h-screen relative overflow-hidden flex justify-center items-center lg:flex hidden"
      }
      style={{ background: "linear-gradient(150.28deg, #152E71 0%, #0D2565 100%)" }}
    >
      {leftArea ?? <div />}
    </div>
    <div
      className={
        "relative flex flex-col items-center flex-1 p-5 pt-24 lg:justify-center sm:p-8 md:overflow-y-auto md:p-8"
      }
    >
      <div className={"w-full md:w-[400px] 2xl:flex-none h-full flex items-center flex-col"}>
        {children}
      </div>
      {/* {showFooter ? <Footer /> : null} */}
    </div>
  </div>
)

export default AuthLayout
